<template>
  <div class="row  xs-fill-white pt-20 lg:px-60" style="margin: auto; max-width: 990px">
    <div class="selection-time text-center pb20 aboutus-wrapper " style="position: relative;overflow: hidden; margin: auto;max-width: 960px;text-align: left;font-size: 14px">
      <div class="list-news">
        <div class="media">
          <div class="media-left" style="width: 40%">
              <img width="100" class="media-object" src="../assets/images/icons/yes.png">
          </div>
          <div class="media-body">
            <h4 class="media-heading">{{$t('registration.confirm.success')}}</h4>
            <div>
              {{message}} {{time}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted () {
    this.confirm()
    this.toggleTimer()
  },
  data: function () {
    return {
      time: 5,
      interval: null,
      message: ''
    }
  },
  methods: {
    toggleTimer () {
      clearInterval(this.interval)
      this.interval = setInterval(this.incrementTime, 1000)
    },
    incrementTime () {
      this.time = parseInt(this.time) - 1
    },
    confirm () {
      let vm = this
      let data = this.$route.params.regis
      this.axios.registerConfirmation(data,
        (response) => {
          if (response.data.data === true) {
            vm.message = vm.$t('registration.confirm.redirect')
          }
        }
      )
    }
  },
  watch: {
    time () {
      if (this.time === 0) { this.$router.push('/login') }
    }
  }
}
</script>
<style>
  .media-left img {
    float: right;
  }
  .media-body {
    vertical-align: middle;
  }
</style>
